<script setup lang="ts">
import { ConfigGlobal } from '@/components/ConfigGlobal'
const greyMode = false // 灰色模式
import LoginDialog from '@/components/LoginDialog/Index.vue'
</script>

<template>
    <ConfigGlobal>
        <RouterView :class="greyMode ? `app-grey-mode` : ''" />
    </ConfigGlobal>
    <!-- 登录弹框 -->
    <LoginDialog />
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app'; // cy-app

.size {
    width: 100%;
    height: 100%;
}

html,
body {
    padding: 0 !important;
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    .size;

    #app {
        .size;
    }
}

.app-grey-mode {
    color: red;
    filter: grayscale(100%);
}
</style>
