<script lang="tsx">
import { computed, unref, defineComponent, watch, ref, PropType } from 'vue'
import { setActivePinia } from 'pinia'
import { useRouter } from 'vue-router'
import { ElMenu, ElScrollbar } from 'element-plus'
import { useAppStore } from '@/stores/modules/app'
import { usePermissionStore } from '@/stores/modules/permission'
import { isUrl } from '@/utils/is'
import { useRenderMenuItem } from './components/useRenderMenuItem'

import wxImg from '@/assets/imgs/home/weChat.png'
import video1 from '@/assets/imgs/home/video1.png'

// 找不到pinia的store，所以这里手动设置一下
import { store } from '@/stores'
setActivePinia(store)

export default defineComponent({
    name: 'Menu',
    props: {
        menuSelect: {
            type: Function as PropType<(index: string) => void>,
            default: undefined
        }
    },
    setup() {
        const appStore = useAppStore()
        const permissionStore = usePermissionStore()
        const { push, currentRoute } = useRouter()
        const parentPath = ref('/')
        const routers = computed(() => permissionStore.getRouters)
        const layout = computed(() => appStore.getLayout)
        const tabRouters = computed(() => unref(routers).filter((v) => !v?.meta?.hidden))
        const collapse = computed(() => appStore.getCollapse)
        // const menuMode = ref('vertical')
        const menuMode = computed((): 'vertical' | 'horizontal' => {
            const vertical: LayoutType[] = ['classic', 'topLeft']
            return 'vertical'
        })

        // 菜单高亮
        const activeMenu = computed(() => {
            const { meta, path } = unref(currentRoute)
            if (meta.activeMenu) {
                return meta.activeMenu as string
            }
            return path
        })

        // 菜单激活的回调
        const menuSelect = (index: string) => {
            // 自定义事件
            if (isUrl(index)) {
                window.open(index)
            } else {
                push(index)
            }
        }

        // 常见问题
        const questionList = [
            {
                title: '甩手跨境工具箱Temu标签合成如何使用？',
                link: 'https://help.shuaishou.com/front/docDetail/394?label=%E6%A0%87%E7%AD%BE%E6%89%93%E5%8D%B0'
            },
            {
                title: '标签导出失败的常见原因及解决方式',
                link: 'https://help.shuaishou.com/front/docDetail/394?label=%E6%A0%87%E7%AD%BE%E6%89%93%E5%8D%B0'
            },
            {
                title: 'TEMU商品条码如何获取？',
                link: 'https://help.shuaishou.com/front/docDetail/394?label=%E6%A0%87%E7%AD%BE%E6%89%93%E5%8D%B0'
            }
        ]

        const renderMenu = () => {
            console.log(activeMenu, 'activeMenu')
            return (
                <ElMenu
                    defaultActive={unref(activeMenu)}
                    mode={unref(menuMode)}
                    collapse={unref(layout) === 'top' || unref(layout) === 'cutMenu' ? false : unref(collapse)}
                    backgroundColor="var(--left-menu-bg-color)"
                    textColor="var(--left-menu-text-color)"
                    activeTextColor="var(--left-menu-text-active-color)"
                    onSelect={menuSelect}
                >
                    {{
                        default: () => {
                            const { renderMenuItem } = useRenderMenuItem(unref(menuMode))
                            return renderMenuItem(unref(routers))
                        }
                    }}
                </ElMenu>
            )
        }
        /*  const renderMenuWrap = () => {
            if (unref(layout) === 'top') {
                return renderMenu()
            } else {
                return <ElScrollbar>{renderMenu()}</ElScrollbar>
            }
        } */

        // const wxImg = new URL('@/assets/imgs/server.png', import.meta.url).href

        return () => (
            <div
                class={[
                    `${unref(menuMode)}`,
                    'layout-menu h-[100%] overflow-hidden flex-col bg-[var(--left-menu-bg-color)] relative box-border',
                    {
                        'w-[var(--left-menu-min-width)]': unref(collapse) && unref(layout) !== 'cutMenu',
                        'w-[var(--left-menu-max-width)]': !unref(collapse) && unref(layout) !== 'cutMenu'
                    }
                ]}
            >
                <ElScrollbar>{renderMenu()}</ElScrollbar>
                <div class="absolute serveBox px-10px box-border pb-16px">
                    {/* <el-card style="max-width: 480px">
                        <template #header>
                        <div class="card-header">
                            <span>Card name</span>
                        </div>
                        </template>
                        <p v-for="o in 4" :key="o" class="text item">{{ 'List item ' + o }}</p>
                        <template #footer>Footer content</template>
                    </el-card> */}
                    <div class="w-100% mb-12px card-cont bg-#FAFCFF! p-10px box-border color-#333" style="border: 1px solid #E4E7ED;">
                        <div class=" flex justify-between">
                            <div class=" flex items-center color-#000">
                                <i class="iconfont-temu iconTemu-shipinjiaocheng mr-4px"></i>
                                <span class="fw-bold">视频教程</span>
                            </div>
                            <el-icon size={14} class="mr-4px " color="#909399">
                                <ArrowRight />
                            </el-icon>
                        </div>
                        <div class="100% mt-10px">
                            <img
                                src={video1}
                                class="w-100% cursor-pointer"
                                onClick={() => window.open('https://www.douyin.com/user/self?modal_id=7345318084934225191', '_blank')}
                            />
                        </div>
                    </div>
                    <div class="w-100% mb-22px px-12px py-14px card-cont bg-#f5f7fa! box-border color-#333">
                        <div class=" flex justify-between">
                            <div class=" flex items-center color-#000">
                                <i class="iconfont-temu iconTemu-changjianwenti mr-4px"></i>
                                <span class="fw-bold">常见问题</span>
                            </div>
                            <el-link
                                class="color-#909399!"
                                underline={false}
                                href="https://help.shuaishou.com/front/docDetail/394?label=%E6%A0%87%E7%AD%BE%E6%89%93%E5%8D%B0"
                                target="_blank"
                            >
                                更多
                            </el-link>
                        </div>
                        <el-divider class="my-12px!" />
                        <div class="text-left ">
                            {questionList.map((qus) => (
                                <el-link class="line-height-30px color-#303133!" key={qus.title} underline={false} href={qus.link} target="_blank">
                                    {qus.title}
                                </el-link>
                            ))}
                        </div>
                    </div>
                    <div class="mb-12px">
                        <img src={wxImg} style="  width: 150px;height: 150px;" />
                    </div>
                    <span class="color-#909399"> 使用中有任何问题，欢迎咨询客服</span>
                </div>
            </div>
        )
    }
})
</script>

<style lang="less" scoped>
:deep(.el-sub-menu__title) {
    display: none;
}

.layout-menu {
    position: relative;
    :deep(.el-menu) {
        width: 100% !important;
        border-right: none;

        // 设置选中时子标题的颜色
        .is-active {
            & > .el-sub-menu__title {
                color: var(--left-menu-text-active-color) !important;
                // background-color: var(--left-menu-bg-color) !important;
            }
        }

        // 设置子菜单悬停的高亮和背景色
        .el-sub-menu__title,
        .el-menu-item {
            &:hover {
                color: var(--left-menu-text-active-color) !important;
                background-color: var(--left-menu-bg-color) !important;
            }
        }

        // 设置选中时的高亮背景和高亮颜色
        .el-menu-item.is-active {
            color: var(--left-menu-text-active-color) !important;
            background-color: var(--left-menu-bg-active-color) !important;

            &:hover {
                background-color: var(--left-menu-bg-active-color) !important;
            }
        }

        .el-menu-item.is-active {
            position: relative;

            // &:after {
            //   .is-active--after;
            // }
        }

        // 设置子菜单的背景颜色
        .el-menu {
            .el-sub-menu__title,
            .el-menu-item:not(.is-active) {
                background-color: var(--left-menu-bg-light-color) !important;
            }
        }
    }
    // 折叠动画的时候，就需要把文字给隐藏掉
    // :deep(.horizontal-collapse-transition) {
    //     .@{prefix-cls}__title {
    //         display: none;
    //     }
    // }
    .serveBox {
        font-size: 14px;
        color: #666;
        width: 100%;
        text-align: center;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .card-cont {
            box-shadow: none;
            border: none;
            border-radius: 8px;
        }

        img {
            object-fit: contain;
        }
    }
}
</style>
