<script setup lang="ts">
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessage } from 'element-plus'
import type { BaseForm } from './types.ts'
import { validPhone, validPassword } from '@/utils/validate'
import { reactive, ref, watch } from 'vue'
import { useUserStore } from '@/stores/modules/user'
const userStore = useUserStore()
import type { RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router'
import { usePermissionStore } from '@/stores/modules/permission'
import { useRouter } from 'vue-router'
const permissionStore = usePermissionStore()
const { currentRoute, addRoute, push } = useRouter()

const baseForm = reactive<BaseForm>({
    phone: '',
    password: '',
    smsCode: ''
})

const logingType = ref<string>('account')

const formRefs = ref<FormInstance>()
let codeTime: any = null

const phoneRefs = ref<any>(null)

let codeText = ref<string | number>('发送验证码')
let isCode = ref<boolean>(false)

const sendCode = () => {
    const { phone } = baseForm
    if (!phone) return phoneRefs.value.focus()
    if (!validPhone(phone)) return
    userStore.USER_SENDCODE({ phone: phone }).then((res) => {
        let number = 30
        isCode.value = true
        codeTime = setInterval(() => {
            codeText.value = number + 's'
            number--
            if (number <= 0) {
                clearInterval(codeTime)
                codeText.value = '发送验证码'
                isCode.value = false
            }
        }, 1000)
    })
}

const rules = reactive<FormRules<BaseForm>>({
    phone: [
        { required: true, message: '请输入手机号码', trigger: 'blur' },
        { validator: (r: any, v: BaseForm['phone'], c: any) => (validPhone(v) ? c() : c(new Error('请输入正确的手机号码'))), trigger: 'blur' }
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        {
            validator: (r: any, v: BaseForm['password'], c: any) => (validPassword(v) ? c() : c(new Error('密码为6-20个字母和数字组合'))),
            trigger: 'blur'
        }
    ],
    smsCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { validator: (r, v, c) => (!baseForm.phone ? c(new Error('请先输入手机号码')) : c()), trigger: 'blur' }
    ]
})

const redirect = ref<string>('')
watch(
    () => currentRoute.value,
    (route: RouteLocationNormalizedLoaded) => {
        redirect.value = route?.query?.redirect as string
    },
    {
        immediate: true
    }
)
const handleTabChange = () => {
    console.log(logingType.value)
    logingType.value === 'account' ? (baseForm.smsCode = '') : (baseForm.password = '')
}
const handleLogin = () => {
    formRefs.value?.validate((v) => {
        if (!v) return
        userStore
            .USER_LOGIN({ ...baseForm })
            .then(async (res) => {
                ElMessage.success('登录成功')
                userStore.setLoginType(false)
                // 存储菜单的所有路由
                await permissionStore.generateRoutes('static').catch(() => {})
                // 添加到路由表
                permissionStore.getAddRouters.forEach((route) => {
                    addRoute(route as RouteRecordRaw)
                })
                push({ path: redirect.value || '/tool/toolEntry' })
                // window.location.reload()
            })
            .catch(() => {
                baseForm.password = ''
            })
    })
}

const emit = defineEmits(['changeCom'])
const handleChange = () => {
    emit('changeCom', 'RegisterForm')
}
</script>
<template>
    <!-- <p class="text-18px font-bold mb-4">登录</p> -->
    <el-tabs v-model="logingType" class="custom-tabs mb-6 flex justify-center" @tab-change="handleTabChange">
        <el-tab-pane label="密码登录" name="account" />
        <el-tab-pane label="验证码登录" name="register" />
    </el-tabs>

    <el-form ref="formRefs" size="large" :model="baseForm" :rules="rules">
        <el-form-item prop="phone">
            <el-input ref="phoneRefs" v-model="baseForm.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item v-if="logingType === 'account'" prop="password">
            <el-input v-model="baseForm.password" type="password" show-password placeholder="请输入密码" />
        </el-form-item>
        <el-form-item v-else prop="smsCode" class="codeBox">
            <el-input v-model="baseForm.smsCode" placeholder="请输入验证码" />
            <el-button :disabled="isCode" class="posText" link @click="sendCode">
                <span class="color-#035dff">{{ codeText }}</span>
            </el-button>
        </el-form-item>
        <el-form-item>
            <el-button class="w-full" style="background-color: #035dff" type="primary" @click="handleLogin">立即登录</el-button>
        </el-form-item>
        <p class="text-right">没有账号? <span class="color-#035dff cursor-pointer" @click="handleChange">立即注册</span> </p>
    </el-form>
</template>
<style lang="less" scoped>
.codeBox {
    position: relative;
    &:deep(.posText) {
        position: absolute;
        right: 10px;
        color: #035dff;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
    }
}
</style>
