<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useAppStore } from '@/stores/modules/app'

// store
const appStore = useAppStore()
const layout = computed(() => appStore.getLayout)
const title = computed(() => appStore.getTitle)

const showTitle = ref(true)
</script>

<template>
    <div>
        <router-link
            :class="[
                'logo',
                layout !== 'classic' ? `logo__Top` : '',
                'flex !h-[var(--logo-height)] items-center cursor-pointer pl-8px relative decoration-none overflow-hidden'
            ]"
            to="/"
        >
            <img src="@/assets/imgs/logo.png" class="w-[calc(var(--logo-height)-10px)] h-[calc(var(--logo-height)-10px)]" />
            <div v-if="showTitle" :class="['ml-10px text-16px text-[var(--logo-title-text-color)]']">
                {{ title }}
            </div>
        </router-link></div
    >
</template>
