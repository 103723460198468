import type { App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/components/Layout/Layout.vue'
import toolRoute from './modules/tool'

const baseUrl = import.meta.env.VITE_API_BASE_PATH

export const constantRouterMap: AppRouteRecordRaw[] = [
    {
        path: '/',
        component: Layout,
        redirect: '/tool/toolEntry',
        name: 'Root',
        meta: {
            hidden: true
        }
    },
    {
        path: '/login',
        component: () => import('@/views/login/index.vue'),
        name: 'Login',
        meta: {
            hidden: true
        }
    },
    {
        path: '/404',
        component: () => import('@/views/Error/404.vue'),
        name: 'NoFind',
        meta: {
            hidden: true,
            title: '404'
        }
    },
    toolRoute
]
export const asyncRouterMap: AppRouteRecordRaw[] = []

const router = createRouter({
    history: createWebHistory(),
    strict: true,
    routes: constantRouterMap as RouteRecordRaw[],
    scrollBehavior: () => ({ left: 0, top: 0 })
})

export const setupRouter = (app: App<Element>) => {
    app.use(router)
}

export default router
