import axios from 'axios'
import { defaultRequestInterceptors, defaultResponseInterceptors } from './interceptors'
import { RequestConfig, InternalAxiosRequestConfig, AxiosResponse, AxiosRequestConfig, AxiosInstance, AxiosRequestHeaders, AxiosError } from './types'
import { ElMessage } from 'element-plus'

export const PATH_URL = import.meta.env.VITE_API_BASE_PATH

// url 对应的 AbortController 映射
const abortControllerMap: Map<string, AbortController> = new Map()

const axiosInstance: AxiosInstance = axios.create({
    timeout: 60000 * 2,
    baseURL: PATH_URL
})

axiosInstance.interceptors.request.use((res: InternalAxiosRequestConfig) => {
    const url = res.url || ''
    const controller = abortControllerMap.get(url)

    // 如果已经有相同的请求正在进行
    if (controller) {
        // controller.abort() // 取消旧的请求
        // return Promise.reject(new Error('重复 request: ' + url)) // 抛出错误，中断请求链
    }

    // AbortController 的 abort 方法会使 AbortController 关联的 signal 会发送一个中断信号
    const newController = new AbortController()
    res.signal = newController.signal // 赋值给配置的 signal 属性
    abortControllerMap.set(url, newController)
    return res
})

// 请求成功返回，移除对应的AbortController
axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => {
        const url = res.config.url || ''
        abortControllerMap.delete(url)
        // 这里不能做任何处理，否则后面的 interceptors 拿不到完整的上下文了
        return res
    },
    (error: AxiosError) => {
        const url = error.config && error.config.url
        if (url) {
            abortControllerMap.delete(url)
        }
        console.log('err： ' + error) // for debug
        ElMessage.error(error.message)
        return Promise.reject(error)
    }
)

// 真正的 interceptors 处理
axiosInstance.interceptors.request.use(defaultRequestInterceptors)
axiosInstance.interceptors.response.use(defaultResponseInterceptors)

const service = {
    request: (config: RequestConfig) => {
        return new Promise((resolve, reject) => {
            // 特殊请求处理，修改config 例如添加请求头或查询参数，或者设置请求的超时时间。
            if (config.interceptors?.requestInterceptors) {
                config = config.interceptors.requestInterceptors(config as any)
            }

            axiosInstance
                .request(config)
                .then((res) => {
                    resolve(res)
                })
                .catch((err: any) => {
                    reject(err)
                })
        })
    },
    cancelRequest: (url: string | string[]) => {
        const urlList = Array.isArray(url) ? url : [url]
        for (const _url of urlList) {
            abortControllerMap.get(_url)?.abort()
            abortControllerMap.delete(_url)
        }
    },
    cancelAllRequest() {
        for (const [_, controller] of abortControllerMap) {
            controller.abort()
        }
        abortControllerMap.clear()
    }
}

export default service
