<script lang="tsx">
import { defineComponent, computed } from 'vue'
import { setActivePinia } from 'pinia'
import { useAppStore } from '@/stores/modules/app'
import { Collapse } from '@/components/Home'
import { Logo } from '@/components/Home'
import { UserInfo } from '@/components/UserInfo'

// 找不到pinia的store，所以这里手动设置一下
import { store } from '@/stores'
setActivePinia(store)

const appStore = useAppStore()
const layout = computed(() => appStore.getLayout)

export default defineComponent({
    name: 'TopHeader',
    setup() {
        return () => (
            <div class="layout-tool-header  h-[var(--app-top-header-height)]  relative px-10px flex items-center justify-between ">
                <Logo style="transition: all .2s;"></Logo>

                <div class="h-full flex items-center">
                    <UserInfo></UserInfo>
                </div>
            </div>
        )
    }
})
</script>

<style scoped>
.layout-tool-header {
    border-bottom: 1px solid #e8e8e8;
}
</style>
