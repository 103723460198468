import { watch, ref } from 'vue'
import { useAppStore } from '@/stores/modules/app'
import { isString } from '@/utils/is'

export const useTitle = (newTitle?: string) => {
    // const { t } = useI18n()
    const appStore = useAppStore()

    const title = ref(newTitle ? `${appStore.getTitle} - ${newTitle}` : appStore.getTitle)

    watch(
        title,
        (n, o) => {
            if (isString(n) && n !== o && document) {
                document.title = n
            }
        },
        { immediate: true }
    )
}
