import Layout from '@/components/Layout/Layout.vue'
import type { ToolPageType } from '@/views/tool/types/index'

type UrlType = { urlType: ToolPageType }

const toolRoute = {
    path: '/tool',
    component: Layout,
    name: 'Tool',
    redirect: '/tool/toolEntry',
    meta: {
        title: '工具箱'
    },
    children: [
        {
            path: 'toolEntry',
            component: () => import('@/views/tool/toolEntry.vue'),
            name: 'ToolEntry',
            meta: {
                hidden: true,
                activeMenu: '/tool/tag',
                title: '跨境Tool'
            }
        },
        {
            path: 'tag',
            component: () => import('@/views/tool/tag/index.vue'),
            name: 'ToolIndex',
            meta: {
                title: 'TEMU标签合成'
            }
        }
        // {
        //     path: 'imagePsRuler',
        //     component: () => import('@/views/tool/imagePs/index.vue'),
        //     name: 'imagePsRuler',
        //     props: { urlType: 'Ruler' } as UrlType,
        //     meta: {
        //         title: 'TEMU标尺工具'
        //     }
        // },
        // {
        //     path: 'imagePsClearPen',
        //     component: () => import('@/views/tool/imagePs/index.vue'),
        //     name: 'imagePsClearPen',
        //     props: { urlType: 'ClearPen' } as UrlType,
        //     meta: {
        //         title: '消除笔'
        //     }
        // },
        // {
        //     path: 'imagePsCutout',
        //     component: () => import('@/views/tool/imagePs/index.vue'),
        //     name: 'imagePsCutout',
        //     props: { urlType: 'Cutout' } as UrlType,
        //     meta: {
        //         title: '智能抠图'
        //     }
        // },
        // {
        //     path: 'imagePsCrop',
        //     component: () => import('@/views/tool/imagePs/index.vue'),
        //     name: 'imagePsCrop',
        //     props: { urlType: 'Crop' } as UrlType,
        //     meta: {
        //         title: '图片裁剪'
        //     }
        // },
        // {
        //     path: 'imagePsTest',
        //     component: () => import('@/views/tool/imagePs/index.vue'),
        //     name: 'imagePsCrop',
        //     props: { urlType: 'GraphIcon' } as UrlType,
        //     meta: {
        //         title: '图片测试'
        //     }
        // }
    ]
}

export default toolRoute
