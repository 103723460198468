<script setup lang="ts"></script>

<template>
    <router-view>
        <template #default="{ Component, route }">
            <!-- <keep-alive> -->
            <component :is="Component" :key="route.fullPath" />
            <!-- </keep-alive> -->
        </template>
    </router-view>

    <!-- <Footer v-if="footer" /> -->
</template>
