import { ElMessageBox } from 'element-plus'

export default function simpleVerify(params) {
    const {
        message = '你确定要删除吗？ 是否继续？',
        type = 'warning',
        title = '提示',
        confirmButtonText = '确认',
        cancelButtonText = '取消',
        showCancelButton = true,
        showConfirmButton = true,
        confirmButtonClass = '',
        showClose = true,
        center = false,
        icon = '',
        customClass = '' // 底部按钮居中 centerBtnStyle
    } = params
    return new Promise((resolve, reject) => {
        ElMessageBox.confirm(message, title, {
            type,
            confirmButtonText,
            cancelButtonText,
            showCancelButton,
            showConfirmButton,
            confirmButtonClass,
            showClose,
            center,
            icon,
            customClass
        })
            .then(() => {
                resolve(true)
            })
            .catch(() => reject(false))
    })
}
