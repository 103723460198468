import { computed, ref } from 'vue'
import { setActivePinia } from 'pinia'
import { ElScrollbar, ElCarousel, ElCarouselItem } from 'element-plus'
import { useAppStore } from '@/stores/modules/app'
import AppView from './AppView.vue'
import TopHeader from './components/TopHeader.vue'
import Logo from '@/components/Home/Logo.vue'
import Menu from '@/components/Menu/src/Menu.vue'
import bannerGif from '@/assets/imgs/home/banner.gif'
import scrollBg from '@/assets/imgs/home/scrollBg.gif'
import scroll1 from '@/assets/imgs/home/scroll1.png'
import scroll2 from '@/assets/imgs/home/scroll2.png'

// 找不到pinia的store，所以这里手动设置一下
import { store } from '@/stores'
setActivePinia(store)

// store
const appStore = useAppStore()
const pageLoading = computed(() => appStore.getPageLoading)
const collapse = computed(() => appStore.getCollapse)

const tagsView = ref(false)
const currentBannerIndex = ref(0)

const bannerHandle = () => {
    const url = ['https://dz.shuaishou.com/home/trusteeship?regVersion=3', 'https://dz.shuaishou.com/home/noSource?regVersion=1']
    window.open(url[currentBannerIndex.value], '_blank')
}

const styles = `
.bannerWrap{
background: url(${scrollBg}) no-repeat center;
}

`

export const useRenderLayout = () => {
    const renderClassic = () => {
        return (
            <div class="flex flex-col h-100%">
                <style>{styles}</style>
                <div class="bg-[var(--top-header-bg-color)]">
                    <div class="bannerWrap h-60px overflow-hidden cursor-pointer" onClick={bannerHandle}>
                        <div class="w-1000px h-60px m-l-300px">
                            <ElCarousel
                                indicator-position="none"
                                height="60px"
                                autoplay
                                arrow="never"
                                interval={6000}
                                motion-blur={true}
                                pause-on-hover={false}
                                onChange={(index) => (currentBannerIndex.value = index)}
                            >
                                <ElCarouselItem>
                                    <img src={scroll1} class=" h-60px" />
                                </ElCarouselItem>
                                <ElCarouselItem>
                                    <img src={scroll2} class=" h-60px" />
                                </ElCarouselItem>
                            </ElCarousel>
                        </div>
                    </div>
                    {/* <img
                        src={bannerGif}
                        class="w-100% cursor-pointer"
                        onClick={() => window.open('https://dz.shuaishou.com/home/trusteeship?regVersion=3', '_blank')}
                    /> */}

                    <TopHeader></TopHeader>
                </div>
                <div class="layout-content flex flex-1 overflow-hidden" style="transition: all var(--transition-time-02);">
                    <Menu class="w-[var(--left-menu-max-width)] p-10px"></Menu>
                    <ElScrollbar v-loading={pageLoading.value} class="layout-content-scrollbar h-full  flex-[1] bg-#f3f4f9 p-20px box-border">
                        <AppView></AppView>
                    </ElScrollbar>
                </div>
            </div>
        )
    }

    const renderTopLeft = () => <></>

    return { renderClassic, renderTopLeft }
}
