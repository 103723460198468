import { CSSProperties } from 'vue'
import { createTypes, VueTypesInterface, VueTypeDef, toValidableType, VueTypeValidableDef } from 'vue-types'

interface ProjectPropsTypes extends VueTypesInterface {
    readonly style: VueTypeValidableDef<CSSProperties>
}

const ProjectTypes = createTypes({
    func: undefined,
    bool: undefined,
    string: undefined,
    number: undefined,
    object: undefined,
    integer: undefined
}) as ProjectPropsTypes

class propTypes extends ProjectTypes {
    static get style() {
        return toValidableType('style', {
            type: [String, Object]
        })
    }
}

export { propTypes }
