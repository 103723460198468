<script setup lang="ts">
import { provide, computed, watch, onMounted } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { setStyleToCssVar } from '@/utils'
import { useAppStore } from '@/stores/modules/app'

const appStore = useAppStore()

const { width, height } = useWindowSize()
// 监听窗口变化
watch(
    () => width.value,
    (width: number) => {
        if (width < 768) {
            setStyleToCssVar('--left-menu-min-width', '0')
        } else {
            appStore.getMobile ? appStore.setMobile(false) : undefined
            setStyleToCssVar('--left-menu-min-width', '64px')
        }
    },
    {
        immediate: true
    }
)
</script>

<template>
    <!-- 用于配置外部公共,主题色、窗口变化等 -->
    <slot></slot>
</template>
