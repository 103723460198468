import { createApp } from 'vue'

// store
import { setupStore } from '@/stores'

// router
import { setupRouter } from './router'
import './router/router'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

// 引入unocss
import 'virtual:uno.css'

// svg注册
import 'virtual:svg-icons-register'

// 引入全局样式
import '@/styles/index.less'

// 引入全局组件
import { setupGlobalCom } from '@/components'

import directive from '@/directive'

import App from './App.vue'

const app = createApp(App)

directive(app)

setupStore(app)

setupGlobalCom(app)

setupRouter(app)

setupElementPlus(app)

app.mount('#app')
