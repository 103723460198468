<script lang="tsx">
import { defineComponent, computed, unref } from 'vue'
import { useAppStore } from '@/stores/modules/app'
import { useRenderLayout } from './useRenderLayout'
import { Backtop } from '@/components/Home'

// store
const appStore = useAppStore()
const layout = computed(() => appStore.getLayout)
const title = computed(() => appStore.getTitle)

const pageLayout = () => {
    const { renderClassic } = useRenderLayout()
    return renderClassic()
}

export default defineComponent({
    name: 'Layout',
    setup() {
        // 页面框架布局,可以不同的屏幕布局render不同的layout
        //  <Backtop></Backtop>
        return () => <section class="w-[100%] h-[100%] relative">{pageLayout()}</section>
    }
})
</script>

<style scoped lang="less"></style>
