<script setup lang="ts">
import { ElMessage } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import type { BaseForm } from './types.ts'
import { validPhone, validPassword } from '@/utils/validate'
import { reactive, ref, watch } from 'vue'

import { useUserStore } from '@/stores/modules/user'

import type { RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router'
import { usePermissionStore } from '@/stores/modules/permission'
import { useRoute, useRouter } from 'vue-router'
const permissionStore = usePermissionStore()
const { currentRoute, addRoute, push } = useRouter()

interface regForm extends BaseForm {
    confirmPas: string
    channel?: string
}
const baseForm = reactive<regForm>({
    phone: '',
    password: '',
    smsCode: '',
    confirmPas: ''
})

const emit = defineEmits(['changeCom'])

const phoneRefs = ref<any>(null)

const formRefs = ref<FormInstance>()

let codeTime: any = null

const userStore = useUserStore()

const rulePass = (r: any, v: regForm['confirmPas'], c: any) => {
    if (!v) {
        c(new Error('请再次输入设置的密码'))
    } else if (v !== baseForm.password) {
        c(new Error('两次输入密码不一致!'))
    }
    c()
}

const rules = reactive<FormRules<regForm>>({
    phone: [
        { required: true, message: '请输入手机号码', trigger: 'blur' },
        { validator: (r: any, v: regForm['phone'], c: any) => (validPhone(v) ? c() : c(new Error('请输入正确的手机号码'))), trigger: 'blur' }
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        {
            validator: (r: any, v: regForm['password'], c: any) => (validPassword(v) ? c() : c(new Error('密码为6-20个字母和数字组合'))),
            trigger: 'blur'
        }
    ],
    smsCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        { validator: (r, v, c) => (!baseForm.phone ? c(new Error('请先输入手机号码')) : c()), trigger: 'blur' }
    ],
    confirmPas: [
        { required: true, message: '请再次输入设置的密码', trigger: 'blur' },
        { validator: rulePass, trigger: 'blur' }
    ]
})

let codeText = ref<string | number>('发送验证码')
let isCode = ref<boolean>(false)
const sendCode = () => {
    const { phone } = baseForm
    if (!phone) return phoneRefs.value.focus()
    if (!validPhone(phone)) return
    userStore.USER_REGISTER_SENDCODE({ phone: phone }).then((res) => {
        let number = 30
        isCode.value = true
        codeTime = setInterval(() => {
            codeText.value = number + 's'
            number--
            if (number <= 0) {
                clearInterval(codeTime)
                codeText.value = '发送验证码'
                isCode.value = false
            }
        }, 1000)
    })
}

const redirect = ref<string>('')
watch(
    () => currentRoute.value,
    (route: RouteLocationNormalizedLoaded) => {
        redirect.value = route?.query?.redirect as string
    },
    {
        immediate: true
    }
)
const route = useRoute()
const handleRegister = () => {
    formRefs.value?.validate(async (v) => {
        if (!v) return
        if (route.query.link) {
            baseForm.channel = route.query.link as string
        }
        await userStore.USER_REGISTER({ ...baseForm })
        ElMessage.success('注册成功,将会自动登录')
        await userStore.USER_LOGIN({ phone: baseForm.phone, password: baseForm.password })
        userStore.setLoginType(false)
        // 存储菜单的所有路由
        await permissionStore.generateRoutes('static').catch(() => {})
        // 添加到路由表
        permissionStore.getAddRouters.forEach((route) => {
            addRoute(route as RouteRecordRaw)
        })
        push({ path: redirect.value || '/tool/toolEntry' })
        // window.location.reload()
    })
}

const handleChange = () => {
    emit('changeCom', 'LoginForm')
}
</script>
<template>
    <p class="text-18px font-bold mb-4">注册账号</p>
    <el-form ref="formRefs" size="large" :rules="rules" :model="baseForm">
        <el-form-item prop="phone">
            <el-input ref="phoneRefs" v-model="baseForm.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item prop="smsCode" class="codeBox">
            <el-input v-model="baseForm.smsCode" placeholder="请输入验证码" />
            <el-button :disabled="isCode" class="posText" link @click="sendCode">
                <span class="color-#035dff">{{ codeText }}</span>
            </el-button>
        </el-form-item>
        <el-form-item prop="password">
            <el-input v-model="baseForm.password" show-password type="password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item prop="confirmPas">
            <el-input v-model="baseForm.confirmPas" show-password type="password" placeholder="确认密码" />
        </el-form-item>
        <el-form-item>
            <el-button style="background-color: #035dff" class="w-full" type="primary" @click="handleRegister">立即注册</el-button>
        </el-form-item>
        <p class="text-right">已有账号? <span class="color-#035dff cursor-pointer" @click="handleChange">立即登录</span> </p>
    </el-form>
</template>
<style lang="less" scoped>
.codeBox {
    position: relative;
    &:deep(.posText) {
        position: absolute;
        right: 10px;
        color: #035dff;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
    }
}
</style>
