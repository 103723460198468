import type { RouteMeta } from 'vue-router'
// import Icon from '@/components/Icon/index.vue'
// import { useI18n } from '@/hooks/web/useI18n'

export const useRenderMenuTitle = () => {
    const renderMenuTitle = (meta: RouteMeta) => {
        // const { t } = useI18n()
        const { title = 'Please set title', icon } = meta

        return icon ? (
            <>
                {/* <Icon icon={meta.icon}></Icon> */}
                <i class={[meta.icon, `iconfont-img mr-10px `]}></i>
                <span class="v-menu__title ">{title}</span>
            </>
        ) : (
            <span class="v-menu__title">{title}</span>
        )
    }

    return {
        renderMenuTitle
    }
}
