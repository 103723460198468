import { AxiosResponse, InternalAxiosRequestConfig } from './types'
import { ElMessage } from 'element-plus'
import qs from 'qs'
import { useUserStore } from '@/stores/modules/user'
import { downloadBlobFile } from '@/utils/file'
// console.log(useUserStore)

const defaultRequestInterceptors = (config: InternalAxiosRequestConfig) => {
    const userStore = useUserStore()
    const { token } = userStore
    if (token && !config['no-Token']) {
        config.headers.token = token
    }
    // 修改 url data params
    if (config.method === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = qs.stringify(config.data)
    }
    if (config.method === 'get' && config.params) {
        let url = config.url as string
        url += '?'
        const keys = Object.keys(config.params)
        for (const key of keys) {
            if (config.params[key] !== void 0 && config.params[key] !== null) {
                url += `${key}=${encodeURIComponent(config.params[key])}&`
            }
        }
        url = url.substring(0, url.length - 1)
        config.params = {}
        config.url = url
    }
    return config
}

const defaultResponseInterceptors = (response: AxiosResponse) => {
    console.log(response, 'response')
    if (response?.config?.responseType === 'blob') {
        if (response.data.type === 'application/json') {
            response.data.text().then((resText) => {
                console.log(resText, 'resText')
                const data = JSON.parse(resText)
                ElMessage.error(data.msg)
            })
            return Promise.reject(response)
        } else {
            // 如果是文件流
            const res = response.data
            console.log(res, 'res')
            const blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
            let fileName = `导出${Date.now()}.xls`
            if (response.headers['content-disposition']) {
                const fileNameSplit = response.headers['content-disposition'].split('=')
                const preFileName = decodeURI(fileNameSplit[fileNameSplit.length - 1])
                fileName = preFileName.replace(/utf-8''/, '')
            }
            downloadBlobFile(URL.createObjectURL(blob), fileName)
            return response
        }
    } else if (response.data.code === 0) {
        // 直接返回后台的数据 {code,data}
        return response.data
    } else {
        ElMessage.error(response?.data?.msg)
        if (response?.data?.code === 401) {
            const userStore = useUserStore()
            userStore.statusLogout()
            userStore.setLoginType(true)
            // const userStore = useUserStoreWithOut()
            // userStore.logout()
        }
        return Promise.reject(response)
    }
}

export { defaultResponseInterceptors, defaultRequestInterceptors }
