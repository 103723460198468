import type { App } from 'vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//  需要全局引入一些组件，如ElScrollbar，不然一些下拉项样式有问题
import { ElLoading, ElScrollbar } from 'element-plus'

const plugins = [ElLoading]

const components = [ElScrollbar]

export const setupElementPlus = (app: App<Element>) => {
    plugins.forEach((plugin) => {
        app.use(plugin)
    })

    // 为了开发环境启动更快，一次性引入所有样式
    if (import.meta.env.VITE_NODE_ENV === 'dev') {
        import('element-plus/dist/index.css')
    }

    // element Icon
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    // element 全局组件
    components.forEach((component) => {
        app.component(component.name!, component)
    })
}
