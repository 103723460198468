export const setStyleToCssVar = (prop: string, val: any, dom = document.documentElement) => {
    dom.style.setProperty(prop, val)
}

/**
 * 获取assets静态资源,无法在 SSR 中使用
 * https://cn.vitejs.dev/guide/assets
 * @param url
 * @returns
 */
export const getAssetsImg = (url: string) => {
    return new URL(`../assets/imgs/${url}`, import.meta.url).href
}
