import { nextTick, unref } from 'vue'
import type { NProgressOptions } from 'nprogress'
import NProgress from 'nprogress'
import { useCssVar } from '@vueuse/core'

const primaryColor = useCssVar('--el-color-primary', document.documentElement)

export const useNProgress = () => {
    NProgress.configure({
        showSpinner: false // 是否显示加载ico
    } as NProgressOptions)

    const initColor = async () => {
        await nextTick()
        const bar = document.getElementById('nprogress')?.getElementsByClassName('bar')[0] as ElRef
        if (bar) {
            bar.style.background = unref(primaryColor.value)
        }
    }

    initColor()

    // 打开进度条
    const start = () => {
        NProgress.start()
    }

    // 关闭进度条
    const done = () => {
        NProgress.done()
    }

    return { start, done }
}
