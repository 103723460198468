//  检验手机号码
export function validPhone(phone: string): boolean {
    const reg = /^1[3456789]\d{9}$/
    return reg.test(phone)
}

//  检验密码
export function validPassword(pass: string): boolean {
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/ //字母数字组合不能为纯字母或纯数字 6-20
    return reg.test(pass)
}
