import { defineStore } from 'pinia'
import { store } from '../index'
import type { BaseForm as UserType } from '@/components/LoginDialog/types'
import { login, logout, register, sendPhoneCode, sendRegPhoneCode } from '@/api/login'
// export interface UserType {
//     username: string
//     password: string

//     // role: string
//     // roleId: string
// }

interface regForm extends UserType {
    confirmPas: string
}

interface UserState {
    userInfo?: UserType
    tokenKey: string
    showLogin: boolean
    token: string
    phone: string
}

export const useUserStore = defineStore('userStore', {
    state: (): UserState => {
        return {
            userInfo: undefined,
            tokenKey: 'Authorization',
            showLogin: false,
            phone: '',
            token: ''
        }
    },
    getters: {
        getUserInfo(): UserType | undefined {
            return this.userInfo
        },
        getToken(): string {
            return this.token
        }
    },
    actions: {
        setLoginType(type: boolean) {
            this.showLogin = type
        },
        setUserInfo(userInfo: UserType) {
            this.userInfo = userInfo
        },
        setToken(token: string) {
            this.token = token
        },
        statusLogout() {
            this.phone = ''
            this.setToken('')
        },
        //  用户登录
        async USER_LOGIN(userInfo: UserType) {
            return login(userInfo)
                .then((res) => {
                    const { phone, token } = res.data
                    this.phone = phone
                    this.setToken(token)
                    return Promise.resolve(res)
                })
                .catch((err) => {
                    return Promise.reject(err)
                })
        },
        //  登录发送验证码
        async USER_SENDCODE(phone: object) {
            const res = await sendPhoneCode(phone)
            return Promise.resolve(res)
        },
        //  注册发生验证码
        async USER_REGISTER_SENDCODE(phone: object) {
            const res = await sendRegPhoneCode(phone)
            return Promise.resolve(res)
        },

        // 注册
        async USER_REGISTER(userInfo: regForm) {
            const res = await register(userInfo)
            return Promise.resolve(res)
        },
        async USER_LOGOUT() {
            await logout()
            this.setToken('')
            this.phone = ''
            Promise.resolve()
            window.location.reload()
        }
    },
    persist: true
})

export const useUserStoreWithOut = () => {
    return useUserStore(store)
}
