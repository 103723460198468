<script setup lang="ts">
// import LoginDialog from '@/components/LoginDialog/Index.vue'
import simpleVerify from '@/utils/element/simpleVerify'
import { ChatDotRound } from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/modules/user'
const userStore = useUserStore()
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
// const show = ref<boolean>(false)
const goServePath = () => {
    window.open('https://wpa1.qq.com/bnj55kpR?_type=wpa&qidian=true')
}
const handleLogout = async () => {
    await simpleVerify({ message: '确定要退出登录吗？' })
    await userStore.USER_LOGOUT()
    ElMessage.success('退出登录成功')
}
</script>
<template>
    <div class="flex">
        <!-- <el-button plain round type="info" :icon="ChatDotRound" @click="goServePath">联系客服</el-button> -->
        <section v-if="userStore.token" class="flex items-center mx-10px">
            <div class="userAvatar">
                <el-icon color="#6c99fc"><Avatar /></el-icon>
            </div>
            <el-popover placement="bottom" :width="200" trigger="click">
                <template #reference>
                    <div class="cursor-pointer">
                        <span class="mx-4px color-#333333">{{ userStore.phone }}</span>
                        <el-icon><CaretBottom /></el-icon>
                    </div>
                </template>
                <div>
                    <p class="color-#333333">欢迎您！{{ userStore.phone }}</p>
                    <el-button class="mt-10px" link @click="handleLogout">退出登录</el-button>
                </div>
            </el-popover>
        </section>

        <el-button v-else round type="primary" class="userServe" @click="userStore.setLoginType(true)">登陆/注册</el-button>
    </div>
    <!-- <LoginDialog v-model:show="show" /> -->
</template>
<style scoped lang="less">
.publicBtn {
    padding: 6px 20px;
    border-radius: 18px;
}
.userServe {
    background: linear-gradient(269deg, #409eff 0%, #447cff 100%);
}

.userAvatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    // color: #6c99fc;
    background-color: #deeaff;
}
</style>
