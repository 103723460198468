import router from './index'
import { useUserStoreWithOut } from '@/stores/modules/user'
import { useTitle } from '@/hooks/useTitle'
import { useNProgress } from '@/hooks/useNProgress'
import { usePageLoading } from '@/hooks/usePageLoading'

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

router.beforeEach(async (to, from, next) => {
    start()
    loadStart()
    const userStore = useUserStoreWithOut()
    if (!userStore.token) {
        // userStore.setLoginType(true)
    }
    if (!userStore.token && to.path !== '/tool/toolEntry') {
        next('/tool/toolEntry')
    }
    // if (userStore.getUserInfo) {
    //     if (to.path === '/login') {
    //         next({ path: '/' })
    //         return
    //     }
    //     next()
    //     return
    // } else {
    //     // 如果已经在登录页面，就不再重定向
    //     if (to.path !== '/login') {
    //         next(`/login?redirect=${to.path}`)
    //         return
    //     }
    // }

    next()
})

router.afterEach((to) => {
    useTitle(to?.meta?.title as string)
    done() // 结束 Progress
    loadDone() // 结束 PageLoading
})
