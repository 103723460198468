<script lang="ts" setup>
import { computed, unref } from 'vue'
import { ElIcon } from 'element-plus'
import { propTypes } from '@/utils/propTypes'

const props = defineProps({
    icon: propTypes.string,
    color: propTypes.string.def('#666'),
    size: propTypes.number.def(20),
    hoverColor: propTypes.string
})

const isLocal = computed(() => props.icon?.startsWith('svg:'))
const symbolId = computed(() => (unref(isLocal) ? `#icon-${props.icon.split('svg:')[1]}` : props.icon))
const getIconifyStyle = computed(() => {
    const { color, size } = props
    return {
        fontSize: `${size}px`,
        color
    }
})
</script>

<template>
    <ElIcon class="layout-icon" :size="size" :color="color">
        <!-- element svg -->
        <slot></slot>

        <!-- local svg  <Icon icon="svg:404" size="40" /> -->
        <svg v-if="isLocal" aria-hidden="true" class="svg-icon">
            <use :xlink:href="symbolId" />
        </svg>

        <!-- <Icon :icon="icon" /> -->
        <i :class="`${icon} iconfont`" :style="getIconifyStyle"></i>
    </ElIcon>
</template>
