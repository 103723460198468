import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'
import { store } from '../index'
import { asyncRouterMap, constantRouterMap } from '@/router'
import { generateRoutesByFrontEnd, generateRoutesByServer, flatMultiLevelRoutes } from '@/utils/router'

export interface PermissionState {
    modulePath: string
    routers: AppRouteRecordRaw[]
    subroute: AppRouteRecordRaw[]
    addRouters: AppRouteRecordRaw[]
    isAddRouters: boolean
    menuTabRouters: AppRouteRecordRaw[]
}

export const usePermissionStore = defineStore('permissionStore', {
    state: (): PermissionState => {
        return {
            modulePath: '/',
            routers: [], // 全部路由
            subroute: [], // 一级以下菜单(拆开顶层路由)
            addRouters: [], // 过滤后的动态路由
            isAddRouters: false,
            menuTabRouters: []
        }
    },
    getters: {
        getRouters(): AppRouteRecordRaw[] {
            return this.routers
        },
        getSubroute(): AppRouteRecordRaw[] {
            return this.subroute
        },
        getAddRouters(): AppRouteRecordRaw[] {
            return flatMultiLevelRoutes(cloneDeep(this.addRouters))
        },
        getMenuTabRouters(): AppRouteRecordRaw[] {
            return this.menuTabRouters
        }
    },
    actions: {
        // 存储菜单的所有路由
        generateRoutes(type: 'server' | 'frontEnd' | 'static', routers?: AppCustomRouteRecordRaw[] | string[]): Promise<unknown> {
            return new Promise<void>((resolve) => {
                let routerMap: AppRouteRecordRaw[] = []
                /*  if (type === 'server') {
                    routerMap = generateRoutesByServer(routers as AppCustomRouteRecordRaw[])  // 后端过滤菜单
                } else if (type === 'frontEnd') {
                    routerMap = generateRoutesByFrontEnd(cloneDeep(asyncRouterMap), routers as string[])  // 前端过滤菜单
                } else {
                    routerMap = cloneDeep(asyncRouterMap)  // 直接读取路由表
                }*/
                routerMap = cloneDeep(asyncRouterMap) // 直接读取路由表
                // 动态路由，404一定要放到最后面
                this.addRouters = routerMap.concat([
                    {
                        path: '/:path(.*)*',
                        redirect: '/404',
                        name: '404Page',
                        meta: {
                            hidden: true,
                            breadcrumb: false
                        }
                    }
                ])
                this.routers = cloneDeep(constantRouterMap).concat(routerMap)
                resolve()
            })
        },
        // 获取一级以下菜单
        setSubroute(currentRoute: AppRouteRecordRaw): void {
            const { path } = currentRoute
            const module = path.split('/')[1]
            this.modulePath = `/${module}`
            this.subroute = cloneDeep(this.routers).find((r) => r.path === `/${module}`)?.children || cloneDeep(this.routers)
        },
        setMenuTabRouters(routers: AppRouteRecordRaw[]): void {
            this.menuTabRouters = routers
        }
    },
    persist: {
        paths: ['routers', 'addRouters', 'setMenuTabRouters', 'subroute']
    }
})

export const usePermissionWithOut = () => {
    return usePermissionStore(store)
}
