import request from '@/axios'

export const login = (data) => {
    return request.post({
        url: '/tool_api/user/login',
        data
    })
}

export const logout = () => {
    return request.post({
        url: '/tool_api/user/logout'
    })
}

export const sendPhoneCode = (params) => {
    return request.get({
        url: '/tool_api/common/sms',
        params
    })
}
export const sendRegPhoneCode = (params) => {
    return request.get({
        url: '/tool_api/common/sms_regist',
        params
    })
}

export const register = (data) => {
    return request.post({
        url: '/tool_api/user/regist',
        data
    })
}
