import service from './service'
// import { useUserStoreWithOut } from '@/store/modules/user'

const request = (option: AxiosConfig) => {
    const { url, method, params, data, headers, responseType } = option
    // const userStore = useUserStoreWithOut()
    return service.request({
        url: url,
        method,
        params,
        data,
        responseType: responseType,
        headers: {
            // 'Content-Type': 'application/json', // 设置默认的话formsData的默认格式覆盖了
            // Authorization: userStore.getToken ?? '',
            ...headers
            // token: '0042f76e-3abe-4917-a381-da8ca1166afd'
        }
    })
}

export default {
    get: <T = any>(option: AxiosConfig) => {
        return request({ method: 'get', ...option }) as Promise<ErpResponse<T>>
    },
    post: <T = any>(option: AxiosConfig) => {
        return request({ method: 'post', ...option }) as Promise<ErpResponse<T>>
    },
    delete: <T = any>(option: AxiosConfig) => {
        return request({ method: 'delete', ...option }) as Promise<ErpResponse<T>>
    },
    put: <T = any>(option: AxiosConfig) => {
        return request({ method: 'put', ...option }) as Promise<ErpResponse<T>>
    },
    cancelRequest: (url: string | string[]) => {
        return service.cancelRequest(url)
    },
    cancelAllRequest: () => {
        return service.cancelAllRequest()
    }
}
