/**
 * http 链接下载
 */
export const downloadHttpFile = async (data: { url: string; fileName?: string }) => {
    fetch(data.url)
        .then((response) => response.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = data.fileName || 'demo.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        })
        .catch((error) => console.error(error))
}

/**
 * blob 下载
 */

export const downloadBlobFile = async (downUrl: string, fileName: string) => {
    const aLinkUrl = document.createElement('a')
    aLinkUrl.href = downUrl
    aLinkUrl.download = fileName
    const clickAlink = (obj) => {
        const ev = document.createEvent('MouseEvents')
        ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        obj.dispatchEvent(ev)
        URL.revokeObjectURL(downUrl)
    }
    clickAlink(aLinkUrl)
}

/**
 * base64格式转化为blob对象
 */
export function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
}

/**
 * base64格式转化为 file对象
 */
// export function dataURItoFile(dataURI, fileName) {
//     const byteString = atob(dataURI.split(',')[1])
//     const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
//     const ab = new ArrayBuffer(byteString.length)
//     const ia = new Uint8Array(ab)
//     for (let i = 0; i < byteString.length; i++) {
//         ia[i] = byteString.charCodeAt(i)
//     }
//     return new File([ab], fileName, { type: mimeString })
// }

/**
 * base64格式转化为 file对象
 */
export const dataURItoFile = (dataurl, filename = 'files') => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const suffix = mime.split('/')[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    })
}

/**
 * HTTP 转为 Blob
 * */
export const httpUrlToBlob = (url: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(xhr.response)
            } else {
                reject(new Error('Failed to load file'))
            }
        }
        xhr.onerror = function () {
            reject(new Error('Failed to load file'))
        }
        xhr.send()
    })
}
