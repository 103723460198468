import { useUserStore } from '@/stores/modules/user'

const Vlogin = {
    mounted: (el: Element, binding: any) => {
        el.addEventListener('click', () => {
            const userStore = useUserStore()
            if (!userStore.token) {
                userStore.showLogin = true
            } else {
                binding.value()
            }
        })
    }
}

export default Vlogin
