import { defineStore } from 'pinia'
import { store } from '../index'

interface AppState {
    layout: string
    title: string
    pageLoading: boolean
    mobile: boolean
    collapse: boolean
    splitTopLevelMenu: boolean
}

export const useAppStore = defineStore('appStore', {
    state: (): AppState => {
        return {
            layout: 'classic', // 布局类型
            title: import.meta.env.VITE_APP_TITLE,
            pageLoading: false, // 路由跳转loading
            mobile: false, // 是否是移动端
            collapse: false, // 菜单是否折叠
            splitTopLevelMenu: true // 拆开顶层路由
        }
    },
    getters: {
        getTitle(): string {
            return this.title
        },
        getPageLoading(): boolean {
            return this.pageLoading
        },
        getMobile(): boolean {
            return this.pageLoading
        },
        getLayout(): string {
            return this.layout
        },
        getCollapse(): boolean {
            return this.collapse
        }
    },
    actions: {
        setPageLoading(pageLoading: boolean) {
            this.pageLoading = pageLoading
        },
        setMobile(mobile: boolean) {
            this.mobile = mobile
        },
        setLayout(value: string) {
            this.layout = value
        },
        setCollapse(value: boolean) {
            this.collapse = value
        }
    }
})

export const useAppStoreWithOut = () => {
    return useAppStore(store)
}
