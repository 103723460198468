<script setup lang="ts">
import { ref, shallowRef } from 'vue'
import { SuccessFilled } from '@element-plus/icons-vue'
import RegisterForm from './RegisterForm.vue'
import LoginForm from './LoginForm.vue'
import { ElIcon } from 'element-plus'
import { useUserStore } from '@/stores/modules/user'
const userStore = useUserStore()
// const props = withDefaults(
//     defineProps<{
//         show: boolean
//     }>(),
//     {
//         show: false
//     }
// )

// const emit = defineEmits(['update:show'])

const switchCom = shallowRef(LoginForm)
const changeCom = (v: string) => {
    const coms = {
        LoginForm: LoginForm,
        RegisterForm: RegisterForm
    }
    switchCom.value = coms[v]
}

const introText: Array<string> = ['TEMU标签合成', 'TEMU图片标尺', '图片翻译', '图片处理', '消除笔', '智能抠图，生成白底图']

const handleClose = () => {
    // emit('update:show', false)
    userStore.setLoginType(false)
}
</script>

<template>
    <teleport to="body">
        <div v-if="userStore.showLogin" class="w-full h-full dialogBox">
            <div class="pos-relative">
                <div class="flex justify-center bg-white w-4xl content">
                    <section class="leftIntro">
                        <img src="~@/assets/imgs/login-left.png" style="width: 100%; height: 100%; object-fit: contain" alt="" />
                        <!-- <div class="flex align-center mb-8">
                            <img src="~@/assets/imgs/whiteLogo.png" alt="" />
                            <span class="text-26px text-white ml-3">甩手跨境工具箱</span>
                        </div>
                        <div class="pl-4 line-height-12">
                            <div v-for="(item, index) in introText" :key="index" class="" style="display: flex; align-items: center">
                                <el-icon color="#a3b8f9" :size="20"><SuccessFilled /></el-icon>
                                <span class="text-white text-16px ml-3">{{ item }}</span>
                            </div>
                        </div> -->
                    </section>
                    <section class="box-border rightForm">
                        <component :is="switchCom" @changeCom="changeCom" />
                    </section>
                </div>
                <section class="pos-absolute closeBox cursor-pointer" @click="handleClose">
                    <el-icon color="#FFFFFF" :size="30"><CircleClose /></el-icon>
                </section>
            </div>
        </div>
    </teleport>
</template>
<style scoped lang="less">
.dialogBox {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        border-radius: 20px;
        overflow: hidden;
        // position: relative;
        .leftIntro {
            padding: 50px 10px 50px 20px;
            flex: 1.7;
            // width: 100%;
            background: url('@/assets/imgs/intro.png') no-repeat;
            background-size: cover;
        }
        .rightForm {
            flex: 2;
            padding: 50px 20px;
        }
    }
    .closeBox {
        right: -30px;
        top: -15px;
    }
}
</style>
